<template>
  <VFileInput
    :value="file"
    :loading="loading"
    :disabled="disabled"
    :label="label"
    :hint="hint"
    persistent-hint
    :clearable="!disabledAlways"
    :outlined="outlined"
    :accept="acceptValue"
    :rules="rules"
    @change="handleUpload"
    @click:clear="clearClick"
  />
</template>

<script>
// TODO: удалить зависимость от axios
import { create } from 'axios';

export default {
  name: 'UploadImage',

  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabledAlways: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      file: null,
      imageUrl: null,
    };
  },

  computed: {
    hint() {
      if (this.disabledAlways) {
        // NOTE: по-старому
        return this.value.length > 0 ? `Файл загружен: ${this.value}` : 'Файл будет загружен автоматически';
      }
      // NOTE: для новой формы
      return this.file && this.value.length > 0 ? `Файл загружен: ${this.value}` : 'Файл будет загружен автоматически';
    },
    disabled() {
      if (this.disabledAlways) {
        // NOTE: по-старому
        return this.loading || this.value.length > 0;
      }
      // NOTE: для новой формы
      return false;
    },
    acceptValue() {
      switch (this.accept) {
        case 'image':
          return 'image/*';
        case 'video':
          return 'video/*';
        default:
          return '';
      }
    },
  },

  methods: {
    clearClick() {
      this.file = null;
    },
    async handleUpload(file) {
      if (!file) return;

      try {
        this.loading = true;
        this.file = file;

        const [, ext] = file.name.split(/\.(png|jpe?g|mp4)$/, 2);
        const dict = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          mp4: 'video/mp4',
        };
        const filename = file.name;
        const type = dict[ext] ?? 'image/jpg';
        const params = { filename, type };

        const s3 = await this.$di.api.Sysadmin.getUpload({ params });
        const data = new FormData();

        Object.entries(s3.fields).forEach(([key, value]) => {
          data.append(key, value);
        });

        data.append('file', file, file.name);

        const config = {
          url: s3.url,
          method: s3.method,
          headers: s3.headers,
          data,
        };

        this.imageUrl = new URL(s3.fields.key, s3.url);

        const transport = create();
        await transport(config);

        this.$emit('input', this.imageUrl.toString());
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
