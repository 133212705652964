<template>
  <VCard>
    <VCardTitle>
      Создание copyright
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Название"
                name="Name"
                persistent-hint
                hint="название copyright"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <UploadImage
                :value="entity.prerollUrl"
                label="Preroll"
                @input="$emit('update:prerollUrl', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <UploadImage
                :value="entity.postrollUrl"
                label="Postroll"
                @input="$emit('update:postrollUrl', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <UploadImage
                :value="entity.watermarkUrl"
                label="Watermark"
                @input="$emit('update:watermarkUrl', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Создать
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import UploadImage from '../../UploadImage.vue';

export default {
  name: 'CopyrightForm',

  components: {
    UploadImage,
  },

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
