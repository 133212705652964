<template>
  <TTView>
    <VRow>
      <VCol>
        <CopyrightForm
          :entity="copyright"
          :loading="loading"
          @update:name="copyright.name = $event"
          @update:prerollUrl="copyright.prerollUrl = $event"
          @update:postrollUrl="copyright.postrollUrl = $event"
          @update:watermarkUrl="copyright.watermarkUrl = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import CopyrightForm from '../../../components/contentStorage/copyright/CopyrightForm.vue';

export default {
  name: 'CopyrightCreate',

  components: {
    CopyrightForm,
  },

  data() {
    return {
      loading: false,
      copyright: {
        name: '',
        prerollUrl: '',
        postrollUrl: '',
        watermarkUrl: '',
      },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const { copyright } = this;
        const data = { copyright };

        await this.$di.api.ContentStorage.copyrightCreate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
